import { useSearchParams } from '@src/utils/Hooks/useSearchParams';
import styles from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Divider, Form, Input, Modal, Select, Spin, message } from 'antd';

import { useHistory } from 'react-router-dom';
import {
    acticlePublishAndSave,
    acticleTagsSearch,
    addArticleTag,
    getActicleDetail,
    searchActicleTitle,
} from './type';
import { GinkgoInput } from '../Transaction/components/TransForm/Forms/FormItems/GinkgoFieldItems';
import ImagePickerModal from './ImagePickerModal';
const { Option } = Select;
const ActicleDetail = () => {
    const [acticleTitle, setActicleTitle] = React.useState('');

    const [tags, setTags] = useState<{ id: number; name: string }[]>([]);

    const [addTagLoading, setAddTagLoading] = useState(false);
    const [showAddTag, setShowAddTag] = useState(false);

    const [searchFailed, setSearchFailed] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const params = useSearchParams();
    const type = parseInt(params.get('type') ?? '1');
    const [searchTagKey, setSearchTagKey] = useState<string>('');

    const [form] = Form.useForm();

    useEffect(() => {
        let id = params.get('id');
        if (id) {
            getActicleDetail({
                doc_release_id: parseInt(id),
            })
                .then((value) => {
                    setTags(value.data.doc_release.tag_info_v2s);
                    let obj = {
                        doc_url: value.data.doc_release?.doc_url,
                        doc_title: value.data.doc_release?.doc_title,
                        poster_url: value.data.doc_release?.poster_url,
                        tag_ids: (value.data.doc_release?.tag_info_v2s ?? []).map(
                            (item: any) => item.id
                        ),
                        release_channel: value.data.doc_release?.release_channel,
                    };
                    setActicleTitle(value.data.doc_release?.doc_title);
                    form.setFieldsValue(obj);
                    if (
                        value.data.doc_release?.poster_url &&
                        value.data.doc_release?.poster_url.length > 0
                    ) {
                        // setFileList([
                        //     {
                        //         uid: '-1',
                        //         name: value.data.doc_release.poster_url.split('/').pop() as string,
                        //         status: 'done',
                        //         url: value.data.doc_release.poster_url,
                        //     },
                        // ]);
                    }
                })
                .catch((err) => {
                    message.error(err.msg);
                });
        }
        //eslint-disable-next-line
    }, [params]);
    const history = useHistory();

    useEffect(() => {
        handleTagSearch('');
    }, []);
    const handleTagSearch = (keyword: string) => {
        setSearchTagKey(keyword);
        acticleTagsSearch({
            keyword,
        })
            .then((value) => {
                if (value.data) {
                    setTags([...(value.data.doc_tag_infos ?? [])]);
                    setShowAddTag(
                        value.data?.doc_tag_infos?.find((item: any) => item.name === keyword) ===
                            undefined
                    );
                }
            })
            .catch((err) => {
                message.error(err.msg);
            });
    };
    const handleNewTag = () => {
        setAddTagLoading(true);
        addArticleTag({
            tag_name: searchTagKey,
        })
            .then((value: any) => {
                if (value.code === 0) {
                    handleTagSearch(searchTagKey);
                }
            })
            .catch((err) => {
                message.error(err.msg);
            })
            .finally(() => {
                setAddTagLoading(false);
            });
    };
    const handleSearchTitle = (link: string) => {
        if (link?.length === 0) {
            return;
        }
        setSearchLoading(true);
        searchActicleTitle({
            doc_url: link,
        })
            .then((value) => {
                if (value.data) {
                    setSearchFailed(false);
                    form.setFieldsValue({
                        doc_title: value.data.doc_title,
                    });
                    setActicleTitle(value.data.doc_title);
                }
            })
            .catch((err) => {
                setSearchFailed(true);
                message.error(err.msg);
            })
            .finally(() => {
                setSearchLoading(false);
            });
    };
    const onFinsh = (values: any) => {
        const type = parseInt(params.get('type') ?? '1');
        acticlePublishAndSave({
            ...values,
            type: type,
        })
            .then((res) => {
                message.success('更新成功');
                history.goBack();
            })
            .catch((err) => {
                message.error(err.msg);
            });
    };
    return (
        <div className={styles.detailContainer}>
            <Form
                form={form}
                onFinish={onFinsh}
                requiredMark={false}
                initialValues={{
                    release_channel: [2, 4],
                }}
                labelAlign="right"
            >
                <h1>文章</h1>
                <Spin spinning={searchLoading}>
                    <Form.Item
                        label="文章链接"
                        name="doc_url"
                        rules={[
                            {
                                required: true,
                                message: '请输入文章链接',
                            },
                        ]}
                        extra={
                            searchFailed && (
                                <Button
                                    onClick={() => {
                                        handleSearchTitle(form.getFieldValue('doc_url'));
                                    }}
                                >
                                    从新抓取标题
                                </Button>
                            )
                        }
                    >
                        <Input
                            className={styles.formItem}
                            onBlur={(e) => {
                                handleSearchTitle(e.currentTarget.value);
                            }}
                        />
                    </Form.Item>
                </Spin>
                <Form.Item
                    label="文章标题"
                    style={{ marginBottom: '0px' }}
                    name="doc_title"
                    extra={
                        <div className={`${styles.formItem} ${styles.titleTip}`}>
                            <span>建议标题长度少于 25 字（资讯页展示为两行）</span>
                            <span>{`${acticleTitle?.length ?? 0} / 50`}</span>
                        </div>
                    }
                >
                    <GinkgoInput className={styles.formItem} readOnly value={acticleTitle} />
                </Form.Item>
                <Form.Item label="文章标签" name="tag_ids">
                    <Select
                        className={styles.formItem}
                        mode="multiple"
                        allowClear
                        showArrow={false}
                        optionFilterProp="children"
                        optionLabelProp="label"
                        onSearch={handleTagSearch}
                        onSelect={() => {
                            setSearchTagKey('');
                        }}
                        searchValue={searchTagKey}
                        placeholder="请输入标签名或添加"
                        dropdownRender={(menu) => (
                            <div>
                                {menu}
                                {showAddTag && (
                                    <>
                                        <Divider style={{ margin: '0px' }} />
                                        <Spin spinning={addTagLoading}>
                                            <div
                                                style={{
                                                    padding: '4px 8px',
                                                    cursor: 'pointer',
                                                    color: 'black',
                                                }}
                                                onClick={handleNewTag}
                                            >
                                                创建新标签: {searchTagKey}
                                            </div>
                                        </Spin>
                                    </>
                                )}
                            </div>
                        )}
                    >
                        {tags?.map((item) => {
                            return (
                                <Option key={item.id} value={item.id} label={item.name}>
                                    {item.name}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="文章头图"
                    name="poster_url"
                    rules={[
                        {
                            required: true,
                            message: '请上传图片',
                        },
                    ]}
                    // extra={
                    //     <>
                    //         {fileList.length > 0 && uploadResponse && (
                    //             <div className={`${styles.titleTip}`}>{`${uploadResponse.width} * ${
                    //                 uploadResponse.height
                    //             } ${(uploadResponse.size / 1024).toFixed(1)}KB`}</div>
                    //         )}
                    //         {fileList.length === 0 && (
                    //             <div>
                    //                 建议尺寸小于100KB,可以前往{' '}
                    //                 <span
                    //                     onClick={() => {
                    //                         window.open('https://tinypng.com/cn/');
                    //                     }}
                    //                     style={{
                    //                         color: 'blue',
                    //                         cursor: 'pointer',
                    //                         textDecoration: 'underline',
                    //                     }}
                    //                 >
                    //                     tinypng.com
                    //                 </span>{' '}
                    //                 进行压缩
                    //             </div>
                    //         )}
                    //     </>
                    // }
                >
                    <ImagePickerModal />
                </Form.Item>
                {/* <Select
                        style={{ width: '100px' }}
                        placeholder="选择图片"
                        optionLabelProp="children"
                        onSelect={(value: any) => {
                            setFileList([
                                {
                                    uid: '-1',
                                    name: 'image.png',
                                    status: 'done',
                                    url: value,
                                },
                            ]);
                        }}
                        disabled={fileList.length > 0}
                    >
                        {
                            <Option value="https://portal.ilookcap.com/order/logo192.png">
                                <Popover
                                    content={
                                        <Image src="https://portal.ilookcap.com/order/logo192.png" />
                                    }
                                >
                                    1212312123
                                </Popover>
                            </Option>
                        }
                    </Select> */}
                <h1>设置</h1>
                <Form.Item
                    label="同步方式"
                    name="release_channel"
                    extra={<div className={styles.titleTip}>如不勾选，则默认生成链接页面</div>}
                >
                    <Checkbox.Group>
                        {[
                            {
                                label: type === 2 ? '鹿可App-管家服务' : '鹿可App',
                                value: 2,
                            },
                            {
                                label: '鹿可百科小程序',
                                value: 1,
                            },
                            {
                                label: '鹿可百科官网',
                                value: 3,
                            },
                            {
                                label: '链接',
                                value: 4,
                            },
                        ].map((item) => {
                            return (
                                <Checkbox
                                    key={item.value}
                                    value={item.value}
                                    disabled={item.value === 4}
                                >
                                    {item.label}
                                </Checkbox>
                            );
                        })}
                    </Checkbox.Group>
                </Form.Item>
                {/* <Form.Item
                    name="featured"
                    label="精选展示"
                    extra={
                        <div className={styles.titleTip}>
                            精选限制三篇文章，选择“是”后文章将在精选第一篇展示，原本的第三篇将结束展示
                        </div>
                    }
                >
                    <Radio.Group>
                        <Radio value={1}>是</Radio>
                        <Radio value={2}>否</Radio>
                    </Radio.Group>
                </Form.Item> */}
                {params.get('id') !== null && (
                    <Button
                        onClick={() => {
                            Modal.confirm({
                                title: '是否取消编辑该文章？所做更改将不会保存',
                                okText: '确认',
                                cancelText: '取消',
                                onOk: () => {
                                    history.goBack();
                                },
                            });
                        }}
                    >
                        取消编辑
                    </Button>
                )}
                <Button type="primary" htmlType="submit">
                    更新同步
                </Button>
            </Form>
        </div>
    );
};

export default ActicleDetail;
